import { UserFragment } from '@/generated/graphql'
import ROUTES from './routes'

type MenuItem = {
  title: string
  path: string
  openExternal?: boolean
  isMentor?: boolean
}

export const mainMenu = [
  { title: 'Referrals', path: ROUTES.MY_REQUESTS.href },
  { title: 'Resume Review', path: ROUTES.RESUME_REVIEW.href },
  // { title: 'Job Interviews', path: '#' },
  // { title: 'Offer Evaluation', path: '#' }, TODO: P2 for now
  // { title: 'Career Advice', path: '#' },
]

export const footerMenu: MenuItem[] = [
  { title: 'Privacy & Terms', path: ROUTES.TERMS_PRIVACY.href },
  ...mainMenu,
  {
    title: 'Discord',
    path: 'https://discord.gg/zmctNsAp',
    openExternal: true,
  },
]

export const authedMenu = (me?: UserFragment | null) => [
  { title: 'Request Referral', path: ROUTES.MY_REQUESTS.href, isMentor: false },
  {
    title: `${me?.mentor?.company.name} Referral Requests`,
    path: ROUTES.REFERRAL_REQUESTS.href,
    isMentor: true,
  },
  { title: 'My Referrals', path: ROUTES.MY_REFERRALS.href, isMentor: true },
  { title: 'Resume Review', path: ROUTES.RESUME_REVIEW.href },
  ...(me?.isAdmin
    ? [
        {
          title: 'Admin',
          path: ROUTES.ADMIN.href,
          isMentor: true,
        },
      ]
    : []),
]
